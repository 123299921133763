import React, { useEffect, useState } from "react";
import "./financialtab.css";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { ExternalPendingPaymentsColoumns, setExternalPendingPaymentsData } from "./Data";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { LoadPanel, Popup } from "devextreme-react";
import { DynamicTabb } from "../../../../../components/DynamicTab/DynamicTab";
import "../financial.css";
import { Toster } from "../../../../../components/Toster/Toster";

export const ExternalPendingPayments = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  const getExternalPendingPayments = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Financial/get-external-payments-grid`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          console.log('data--', val?.data);
          setData(setExternalPendingPaymentsData(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  useEffect(() => {
    getExternalPendingPayments();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />

      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <div className="financial_overview_tab_buttons">
            <p className="page-info-number">OP.3</p>
            <div className="page-info-buttons">
              <div className="buttons" style={{ marginTop: "20px" }}>
                <button className="btn" onClick={getExternalPendingPayments}>
                  Refresh
                  <svg
                    className="svg-blue-dark"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                  >
                    <path
                      d="M7.99905 3.99857V1.20857C7.99905 0.758566 7.45905 0.538566 7.14905 0.858566L3.34905 4.64857C3.14905 4.84857 3.14905 5.15857 3.34905 5.35857L7.13905 9.14857C7.45905 9.45857 7.99905 9.23857 7.99905 8.78857V5.99857C11.7291 5.99857 14.6791 9.41857 13.8591 13.2886C13.3891 15.5586 11.5491 17.3886 9.28905 17.8586C5.71905 18.6086 2.53905 16.1586 2.05905 12.8486C1.98905 12.3686 1.56905 11.9986 1.07905 11.9986C0.479051 11.9986 -0.000948563 12.5286 0.0790514 13.1286C0.699051 17.5186 4.87905 20.7686 9.60905 19.8486C12.7291 19.2386 15.2391 16.7286 15.8491 13.6086C16.8391 8.47857 12.9391 3.99857 7.99905 3.99857Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={600}
              Tablecoloum={ExternalPendingPaymentsColoumns}
              load={load}
              coloumWidthText={"Financial"}
              tableRow={data?.map((val, i) => {
                return { ...val, ID: i };
              })}
              ExternalPendingPaymentsContextMenu={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
