import React, { useEffect, useState } from "react";
import { Overview } from "./components/Overview";
import { PaymentPlan } from "./components/PaymentPlan";
import "./financial.css";
import { ObligationManagement } from "./components/ObligationManagement";
import { Transactions } from "./components/Transactions";
import { handleGetLocal } from "../../../../services/auth-service";
import axios from "axios";
import { LoadPanel } from "devextreme-react";
import { Toster } from "../../../../components/Toster/Toster";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../components/Logout";
import ObligationDetail from "./components/ObligationDetail";
import { OnlinePayment } from "./components/OnlinePayment";

export const Financial = ({
  load,
  userinfo,
  setOverviewData,
  callApi,
  setCallApi,
  overviewData,
  setLoad,
  OverviewList,
  reloadUserInfo,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [financialStatus, setFinancialStatus] = useState([]);
  const [listData, setListData] = useState([]);
  const [paymnetMethodData, setPaymnetMethodData] = useState([]);
  const [reasonListData, setReasonListData] = useState([]);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  const getDetailList = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Payment/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoad(false);
        setListData(val?.data?.data?.cashDrawer);
        setPaymnetMethodData(val?.data?.data?.paymentType);
        val.data.data.financialStatus = [
          ...val?.data?.data?.financialStatus,
          {
            key: "00000000-0000-0000-0000-000000000000",
            value: "-do not change-",
          },
        ];
        setReasonListData(val?.data?.data?.reason);
        setFinancialStatus(val?.data?.data?.financialStatus);
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getDetailList();
    OverviewList();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="financial_tab">
        <div className="financial_tab_nav">
          <button
            className={
              activeTab === "tab1" ? "financial_tab_active financial_tab_nav_button" : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab1")}
          >
            Overview
          </button>
          <button
            className={
              activeTab === "tab2" ? "financial_tab_active financial_tab_nav_button" : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab2")}
          >
            Payment Plan
          </button>
          <button
            className={
              activeTab === "tab3" ? "financial_tab_active financial_tab_nav_button" : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab3")}
          >
            Obligation Management
          </button>
          <button
            className={
              activeTab === "tab4" ? "financial_tab_active financial_tab_nav_button" : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab4")}
          >
            Transactions
          </button>
          <button
            className={
              activeTab === "tab5" ? "financial_tab_active financial_tab_nav_button" : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab5")}
          >
            Obligation Detail
          </button>
          {/* <button
            className={
              activeTab === "tab6" ? "financial_tab_active financial_tab_nav_button" : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab6")}
          >
            Online Payments
          </button> */}
        </div>
        <div className="financial_tab_data">
          {activeTab === "tab1" && (
            <Overview
              setOverviewData={setOverviewData}
              overviewData={overviewData}
              setCallApi={setCallApi}
              callApi={callApi}
              userinfo={userinfo}
              paymnetMethodData={paymnetMethodData}
              setPaymnetMethodData={setPaymnetMethodData}
              listData={listData}
              setListData={setListData}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              reloadUserInfo={reloadUserInfo}
              OverviewList={OverviewList}
            />
          )}
          {activeTab === "tab2" && <PaymentPlan userinfo={userinfo} />}
          {activeTab === "tab3" && (
            <ObligationManagement
              userinfo={userinfo}
              reasonListData={reasonListData}
              financialStatus={financialStatus}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
            />
          )}
          {activeTab === "tab4" && <Transactions userinfo={userinfo} />}
          {activeTab === "tab5" && <ObligationDetail userinfo={userinfo} />}
          {/* {activeTab === "tab6" && <OnlinePayment  userinfo={userinfo}/>} */}
        </div>
      </div>
    </>
  );
};
