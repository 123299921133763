import { useNavigate, useParams } from "react-router-dom";
import {
  allTabDelete,
  remove_dynamicParticipatTab,
  setAddParticipateDockets,
  setAddParticipateStep,
  setAddParticipateUserInfo,
  setAllocationAdjustment,
  setcontectInfoTabWhileAddParticipent,
  setcontectTableAddDataaa,
  setentityperson,
  setsetChangesavedatapopup,
} from "../../reducer/ParticipateReducer";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Button } from "devextreme-react";
import { setdockerValue, setgotooblogationValue } from "../../reducer/participantNoteReducer";
import { setDynamicTab } from "../../reducer/HomePageReducer";

export const TopDynamicTab = ({ text }) => {
  const { id, id2, obligationid, obligationid2 } = useParams();
  const navigate = useNavigate();
  const dspatch = useDispatch();
  const [data, setData] = useState();
  const [onHover, setOnHovrer] = useState(false);
  const {
    dynamicParticipatTab,
    Detail_Notification_Tab_getData,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    participantdata,
    headerCheckBoxChange,
    GoToDocketIsChange,
    GoToobligationIsChange,
  } = useSelector((store) => store.participate);

  useEffect(() => {
    if (dynamicParticipatTab.length == 0) {
      if (
        !Detail_Tab_Name_getData.isChange &&
        !Detail_Tab_address_getData.isChange &&
        !Detail_co_obligor_Tab_getData.isChange &&
        !Detail_Payment_Tab_getData.isChange &&
        !Detail_Notification_Tab_getData.isChange &&
        !headerCheckBoxChange &&
        !GoToobligationIsChange &&
        !GoToDocketIsChange
      ) {
        navigate("/home");
      } else {
        dispatch(setsetChangesavedatapopup(true));
      }
    } else {
      if (data !== undefined && data > dynamicParticipatTab) {
        if (
          dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender ==
          `/obligation/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sDocketNumberCourtCounts?.replace(
            "/",
            "-"
          )}/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.ObligationRef}`
        ) {
          navigate(dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender);
        } else {
          navigate(
            dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender !== "/add-participant"
              ? dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender == "/image-viewer"
                ? "/image-viewer"
                : dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender ==
                  `/docket/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sDocketNumberCourtCounts?.replace(
                    "/",
                    "-"
                  )}`
                ? `/docket/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sDocketNumberCourtCounts?.replace(
                    "/",
                    "-"
                  )}`
                : `/participant/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender}`
              : "/add-participant"
          );
        }

        if (
          dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender ===
          `/docket/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sDocketNumberCourtCounts?.replace(
            "/",
            "-"
          )}`
        ) {
          dispatch(
            setdockerValue({
              getTopDynamicDocketid: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.getTopDynamicDocketid,
              userinfo: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.userinfo,
            })
          );
        }

        if (
          dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender ===
          `/obligation/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sDocketNumberCourtCounts?.replace(
            "/",
            "-"
          )}/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.ObligationRef}`
        ) {
          dispatch(
            setgotooblogationValue({
              gObligationId: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.gObligationId,
              userinfo: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.userinfo,
            })
          );
        }

        if (dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender === `/allocationadjustment`) {
          dispatch(
            setAllocationAdjustment({
              sOffenderNumber: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sOffenderNumber,
              sLastFirst: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.sLastFirst,
              gPersonId: dynamicParticipatTab[dynamicParticipatTab.length - 1]?.gPersonId,
            })
          );

          navigate(`/allocationadjustment`);
        }

        if (dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender === "/documentwizard") {
          navigate(`/documentwizard`);
        }
      }
    }
  }, [dynamicParticipatTab]);

  useEffect(() => {
    if (participantdata && participantdata?.sOffenderNumber === id) {
      if (!dynamicParticipatTab.some((obj) => obj.Offender === id) && participantdata?.sOffenderNumber) {
        dispatch(
          setDynamicTab({
            Offender: participantdata?.sOffenderNumber,
            title: participantdata?.sLastName,
          })
        );
      }
    }
  }, [id, participantdata]);

  useEffect(() => {
    if (id) {
      document.querySelector(".participate").scrollLeft =
        document.querySelector(".active__")?.getBoundingClientRect().left - 110;
    }
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="participate">
          <div className="participate_tab">
            {text.map((val, index) => {
              return (
                <div
                  key={index}
                  className={`participate_tab_tab_block ${id == val?.Offender && "active__"}`}
                  style={{
                    borderBottom:
                      id == val.Offender
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender == "/add-participant" && id == undefined && obligationid2 !== "addObligation"
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender === "/image-viewer" && id == undefined
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender === "/allocationadjustment" && id == undefined && obligationid == undefined
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender === "/documentwizard" && id == undefined && obligationid == undefined
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender === `/docket/${val?.sDocketNumberCourtCounts?.replace(/\//g, "-")}` &&
                          id == val?.sDocketNumberCourtCounts?.replace(/\//g, "-")
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender === `/obligation/${obligationid?.replace(/\//g, "-")}/${obligationid2}` &&
                          obligationid == val?.sDocketNumberCourtCounts?.replace(/\//g, "-")
                        ? "4px solid rgb(66, 65, 103)"
                        : val.Offender === `/obligation/add/addObligation` && obligationid2 == "addObligation"
                        ? "4px solid rgb(66, 65, 103)"
                        : "",
                  }}
                >
                  <p
                    style={{
                      color: id == val.Offender ? "var(--dynamicTabHeadertext1)" : "var(--dynamicTabHeadertext2)",
                      padding: "0 1px",
                      margin: "16px",
                    }}
                    className="TabName"
                    onClick={() => {
                      dispatch(setentityperson(val?.entity ? val?.entity : false));
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !GoToobligationIsChange &&
                        !GoToDocketIsChange
                      ) {
                        if (val.Offender == "/image-viewer") {
                          navigate(val.Offender);
                        } else if (val.Offender == "/allocationadjustment") {
                          dispatch(
                            setAllocationAdjustment({
                              sOffenderNumber: val?.sOffenderNumber,
                              sLastFirst: val?.sLastFirst,
                              gPersonId: val?.gPersonId,
                            })
                          );
                          navigate(val.Offender);
                        } else if (val.Offender == "/documentwizard") {
                          navigate(val.Offender);
                        } else if (val.Offender == `/docket/${val?.sDocketNumberCourtCounts?.replace(/\//g, "-")}`) {
                          dispatch(
                            setdockerValue({
                              getTopDynamicDocketid: val?.getTopDynamicDocketid,
                              userinfo: val?.userinfo,
                            })
                          );
                          navigate(val.Offender);
                        } else if (val.Offender == "/add-participant") {
                          navigate(val.Offender);
                        } else if (
                          val.Offender ==
                          `/obligation/${val?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${val?.["ObligationRef"]}`
                        ) {
                          dispatch(
                            setgotooblogationValue({
                              gObligationId: val?.gObligationId,
                              userinfo: val?.userinfo,
                            })
                          );

                          navigate(val.Offender);
                        } else if (val.Offender == `/obligation/add/addObligation`) {
                          navigate(`/obligation/add/addObligation`);
                        } else {
                          navigate(`/participant/${val.Offender}`);
                        }
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    {val.title}
                  </p>
                  <svg
                    className="svg-blue-white"
                    style={{
                      marginTop: "3px",
                      marginRight: "9px",
                      marginLeft: "-6px",
                    }}
                    width="12"
                    height="12"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !GoToobligationIsChange &&
                        !GoToDocketIsChange
                      ) {
                        if (val.Offender === "/add-participant") {
                          dispatch(setAddParticipateStep(1));
                          dispatch(setAddParticipateDockets([]));
                          dispatch(setAddParticipateUserInfo({}));
                          dispatch(setcontectInfoTabWhileAddParticipent([]));
                          dispatch(setcontectTableAddDataaa([]));
                        }
                        dispatch(remove_dynamicParticipatTab(val.Offender));
                        setData(dynamicParticipatTab);
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    <path
                      d="M9.19656 0.807136C8.93656 0.547136 8.51656 0.547136 8.25656 0.807136L4.99656 4.06047L1.73656 0.800469C1.47656 0.540469 1.05656 0.540469 0.796562 0.800469C0.536563 1.06047 0.536563 1.48047 0.796562 1.74047L4.05656 5.00047L0.796562 8.26047C0.536563 8.52047 0.536563 8.94047 0.796562 9.20047C1.05656 9.46047 1.47656 9.46047 1.73656 9.20047L4.99656 5.94047L8.25656 9.20047C8.51656 9.46047 8.93656 9.46047 9.19656 9.20047C9.45656 8.94047 9.45656 8.52047 9.19656 8.26047L5.93656 5.00047L9.19656 1.74047C9.4499 1.48714 9.4499 1.06047 9.19656 0.807136Z"
                      fill={id == val.Offender ? "rgb(66, 65, 103)" : "rgba(0, 0, 0, 0.5)"}
                    />
                  </svg>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="allTabClose"
          onMouseOver={() => {
            setOnHovrer(true);
          }}
          onMouseLeave={() => {
            setOnHovrer(false);
          }}
        >
          <Button
            onClick={() => {
              dispatch(allTabDelete());
            }}
            icon="remove"
            className="alltabclose"
          />
          {onHover && (
            <div
              style={{
                position: "absolute",
                width: "85px",
                height: "36px",
                background: "var(--dynamicTabHeader)",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                borderRadius: "4px",
                textAlign: "center",
                marginLeft: "-3.5%",
                marginTop: ".4%",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                  fontWeight: 600,
                }}
              >
                {"Close All Tab"}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
