// import { useState } from "react";
// import { TopDynamicTab } from "../../TopDynamicTab";
// import { useSelector } from "react-redux";
import { GoTOObligationHeader } from "./GoTOObligationHeader";
import { DynamicTabb } from "../../../../components/DynamicTab/DynamicTab";

export const G0ToObligation = () => {
  // const { dynamicParticipatTab } = useSelector((store) => store.participate);

  return (
    <>
      <div className="particcipentPage">
        {/* <TopDynamicTab text={dynamicParticipatTab} /> */}
        <DynamicTabb />
        <GoTOObligationHeader />
      </div>
    </>
  );
};
