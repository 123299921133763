import React, { useEffect, useState } from "react";
import "./financialtab.css";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { OnlinePaymentTablecoloumn, setOnlinePaymentTableData } from "./Data";
import { ApiError } from "../../../../Error/ApiError";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { VoidPaymentPopup } from "./overviewPopUp/VoidPaymentPopup";
import { LoadPanel, Popup } from "devextreme-react";
import NewReallocatePaymentPopup from "./overviewPopUp/NewReallocatePaymnetPopup";
import { RefundCCPayment } from "./overviewPopUp/RefundCCPayment";
import { DynamicTabb } from "../../../../../components/DynamicTab/DynamicTab";
import FinancialCrumbs from "../../../../Financial/components/FinancialCrumbs";
import { EditPopup } from "./overviewPopUp/EditPopup";
import { RecivePaymentModel } from "../../../HeaderPopupComponent/RecivePaymentModel";
import "../financial.css";
import { EditConfirmMessage } from "./overviewPopUp/EditConfirmMessage";
import { Toster } from "../../../../../components/Toster/Toster";

const VoidPayment = ({
  voidPaymentPopup,
  setVoidPaymentPopup,
  userinfo,
  reportDataId,
  rowInfoData,
  amountData,
  gPaymentId,
  setCallApi,
  callApi,
  toastConfig,
  setToastConfig,
}) => {
  return (
    <>
      <Popup
        visible={voidPaymentPopup}
        onHiding={() => {
          setVoidPaymentPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPaymentPopup
          setVoidPaymentPopup={setVoidPaymentPopup}
          userinfo={userinfo}
          reportDataId={reportDataId}
          amountData={amountData}
          rowInfoData={rowInfoData}
          gPaymentId={gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

const RecivePaymentModal = ({
  recivePaymentModel,
  setRecivePaymentModel,
  setCallApi,
  callApi,
  gPersonId,
  personID,
  rowInfoData,
  editParticipant,
  userinfo,

  name,
  toastConfig,
  setToastConfig,
}) => {
  return (
    <>
      <Popup
        visible={recivePaymentModel}
        onHiding={() => {
          setRecivePaymentModel(false);
        }}
        showTitle={false}
        width={768}
        height={670}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RecivePaymentModal
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setCallApi={setCallApi}
          callApi={callApi}
          userinfo={userinfo}
          rowInfoData={rowInfoData}
          gPersonId={gPersonId}
          personID={personID}
          recivePaymentModel={recivePaymentModel}
          setRecivePaymentModel={setRecivePaymentModel}
        />
      </Popup>
    </>
  );
};
const EditPopUp = ({
  editPopup,
  setEditPopup,
  userinfo,
  gPaymentId,
  setCallApi,
  callApi,
  listData,
  setListData,
  cashDrawer,
  setcashDrawer,
  paymentDate,
  transactionId,
  paymnetMethodData,
  setPaymnetMethodData,
  rowInfoData,
  setRowInfoData,
  toastConfig,
  setToastConfig,
  paymentMethod,
}) => {
  return (
    <>
      <Popup
        visible={editPopup}
        onHiding={() => {
          setEditPopup(false);
        }}
        showTitle={false}
        width={768}
        height={670}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EditPopup
          setEditPopup={setEditPopup}
          userinfo={userinfo}
          gPaymentId={gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          setPaymnetMethodData={setPaymnetMethodData}
          paymnetMethodData={paymnetMethodData}
          listData={listData}
          setListData={setListData}
          rowInfoData={rowInfoData}
          cashDrawer={cashDrawer}
          setcashDrawer={setcashDrawer}
          setRowInfoData={setRowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          paymentDate={paymentDate}
          transactionId={transactionId}
          paymentMethod={paymentMethod}
        />
      </Popup>
    </>
  );
};

export const OnlinePayment = ({
  setCallApi,
  callApi,
  userinfo,
  // listData,
  // setListData,
  // setPaymnetMethodData,
  // paymnetMethodData,
  // toastConfig,
  // setToastConfig,
  reloadUserInfo,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [paymnetMethodData, setPaymnetMethodData] = useState([]);
  const [paymentDate, setPaymentDate] = useState("");
  const [load, setLoad] = useState(false);
  const [voidPaymentPopup, setVoidPaymentPopup] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);
  const [rowInfoData, setRowInfoData] = useState([]);
  const [amountData, setAmountData] = useState("");
  const [gPaymentId, setGPaymentId] = useState("");

  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [recivePaymentModel, setRecivePaymentModel] = useState(false);
  const [refundCCPaymentPopup, setrefundCCPaymentPopup] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const [reallocatePaymentPopup, setReallocatePaymentPopup] = useState(false);
  const [voidProntoCashConfirmationPopup, setVoidProntoCashConfirmationPopup] = useState(false);
  const [amount, setAmount] = useState("");
  const [gExternalPaymentId, setExternalPaymentId] = useState("");
  const [dates, setDates] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();
  const [gPersonId, setGPersonId] = useState("");
  const [personID, setPersonID] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cashDrawer, setcashDrawer] = useState("");

  const getOnlinePaymentList = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Financial/get-online-payments-grid?gPersonId=
00000000-0000-0000-0000-000000000000`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          // const gPersonId = val?.data?.data?.[0]?.gPersonId;
          // setPersonID(gPersonId);
          // const payment=val?.data?.data?.[0].gPaymentId
          // setGPaymentId(payment);
          const date = val.data?.data?.[0]?.dDoe;
          if (date) {
            const formattedDate = new Date(date).toLocaleString();
            setPaymentDate(formattedDate);
          } else {
            console.log("Date is undefined or invalid");
          }
          // Filter the data based on the specified conditions
          const filteredData = val.data.data.filter((item) => item.fAmountToAllocate > 0 && !item.bVoid);
          setData(setOnlinePaymentTableData(filteredData));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const voidExternalPayment = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Financial/void-external-payment`,
        {
          gExternalPaymentId: gExternalPaymentId,
          comments: "test",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        setVoidProntoCashConfirmationPopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Payment void successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const getPaymentDetail = async () => {
    setLoad(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Financial/get-payment-details?gPaymentID=${gPaymentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          const transactionID = val?.data?.data?.sCcTransactionId;
          setTransactionId(transactionID);
          console.log("data", transactionId);
          const payment = val?.data?.data?.sPaymentMethodName;
          setPaymentMethod(payment);
          const cash = val?.data?.data?.sCashDrawerName;
          setcashDrawer(cash);
          console.log("paymentDetails", val?.data?.data?.sCashDrawerName);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  const getDetailList = async () => {
    setLoad(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Payment/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          console.log("dtaaa", val?.data.data);

          seterrorhandling(false);
          setListData(val?.data?.data?.cashDrawer);
          setPaymnetMethodData(val?.data?.data?.paymentType);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        console.error("API Error:", val);
        seterrorhandling(true);
      });
  };
  useEffect(() => {
    getOnlinePaymentList();
  }, [userinfo?.gPersonId]);

  useEffect(() => {
    getDetailList();
  }, []);
  useEffect(() => {
    if (gPaymentId) {
      getPaymentDetail();
    }
  }, [gPaymentId]);

  const date = new Date();

  const usDateTimeFormat = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  const today = usDateTimeFormat.format(date);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <VoidPayment
        setVoidPaymentPopup={setVoidPaymentPopup}
        voidPaymentPopup={voidPaymentPopup}
        amountData={amountData}
        rowInfoData={rowInfoData}
        gPaymentId={gPaymentId}
        setCallApi={setCallApi}
        callApi={callApi}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      <Popup
        visible={reallocatePaymentPopup}
        onHiding={() => {
          setReallocatePaymentPopup(false);
        }}
        showTitle={false}
        width={982}
        height={722}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        {/* <ReallocatePaymentPopup
          reallocatePaymentPopup={reallocatePaymentPopup}
          setReallocatePaymentPopup={setReallocatePaymentPopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          fieldID={rowInfoData?.gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          refreshApi={reloadUserInfo}
          typePopup={"Overview"}
        /> */}
        <NewReallocatePaymentPopup
          reallocatePaymentPopup={reallocatePaymentPopup}
          setReallocatePaymentPopup={setReallocatePaymentPopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          fieldID={rowInfoData?.gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          refreshApi={reloadUserInfo}
          typePopup={"Overview"}
          rowInfoData={rowInfoData}
        />
      </Popup>

      <EditPopUp
        editPopup={editPopup}
        setEditPopup={setEditPopup}
        setCallApi={setCallApi}
        callApi={callApi}
        cashDrawer={cashDrawer}
        setcashDrawer={setcashDrawer}
        gPaymentId={gPaymentId}
        setPaymnetMethodData={setPaymnetMethodData}
        paymnetMethodData={paymnetMethodData}
        listData={listData}
        setListData={setListData}
        rowInfoData={rowInfoData}
        setRowInfoData={setRowInfoData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        // PaymentDate={paymentDate}
        paymentDate={paymentDate}
        transactionId={transactionId}
        paymentMethod={paymentMethod}
      />
      <Popup
        visible={recivePaymentModel}
        onHiding={() => {
          // setPaymentModal(false);
          setRecivePaymentModel(false)
        }}
        showTitle={false}
        width={1500}
        height={900}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RecivePaymentModel
          userinfo={userinfo}
          setRowInfoData={setRowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          recivePaymentModel={recivePaymentModel}
          setRecivePaymentModel={setRecivePaymentModel}
          gPersonId={gPersonId}
          setPaymentModal={setPaymentModal}
          personID={personID}
        />
      </Popup>

      <Popup
        visible={voidProntoCashConfirmationPopup}
        onHiding={() => {
          setVoidProntoCashConfirmationPopup(false);
        }}
        showTitle={false}
        width={800}
        height={230}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="edit_confirmation">
          <div className="popup_header">
            <div className="popup_header_title">
              <div class="popup_header_title_data">
                <p>Confirmation</p>
              </div>
            </div>
            <svg
              onClick={() => {
                setVoidProntoCashConfirmationPopup(false);
              }}
              style={{
                cursor: "pointer",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="edit_confirmation_content">
            <p className="sub_heading">
              Void Payment of ${amount && amount} entered on {today}?
            </p>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={voidExternalPayment}>
              Yes
            </button>
            <button
              className="btn edit_payment_btn"
              onClick={() => {
                setVoidProntoCashConfirmationPopup(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Popup>

      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <div className="financial_overview_tab_buttons">
            <p className="page-info-number">OP.2</p>
            <div className="page-info-buttons">
              <div className="buttons" style={{ marginTop: "20px" }}>
                <button className="btn" onClick={getOnlinePaymentList}>
                  Refresh
                  <svg
                    className="svg-blue-dark"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                  >
                    <path
                      d="M7.99905 3.99857V1.20857C7.99905 0.758566 7.45905 0.538566 7.14905 0.858566L3.34905 4.64857C3.14905 4.84857 3.14905 5.15857 3.34905 5.35857L7.13905 9.14857C7.45905 9.45857 7.99905 9.23857 7.99905 8.78857V5.99857C11.7291 5.99857 14.6791 9.41857 13.8591 13.2886C13.3891 15.5586 11.5491 17.3886 9.28905 17.8586C5.71905 18.6086 2.53905 16.1586 2.05905 12.8486C1.98905 12.3686 1.56905 11.9986 1.07905 11.9986C0.479051 11.9986 -0.000948563 12.5286 0.0790514 13.1286C0.699051 17.5186 4.87905 20.7686 9.60905 19.8486C12.7291 19.2386 15.2391 16.7286 15.8491 13.6086C16.8391 8.47857 12.9391 3.99857 7.99905 3.99857Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={600}
              Tablecoloum={OnlinePaymentTablecoloumn}
              load={load}
              coloumWidthText={"Financial"}
              tableRow={data?.map((val, i) => {
                return { ...val, ID: i };
              })}
              contextMenus={true}
              setEditPopup={setEditPopup}
              setVoidPaymentPopup={setVoidPaymentPopup}
              setRowInfoData={setRowInfoData}
              exportPopupOpen={exportPopupOpen}
              setReallocatePaymentPopup={setReallocatePaymentPopup}
              setVoidProntoCashConfirmationPopup={setVoidProntoCashConfirmationPopup}
              setAmount={setAmount}
              setListData={setListData}
              setRecivePaymentModel={setRecivePaymentModel}
              setExternalPaymentId={setExternalPaymentId}
              setGPaymentId={setGPaymentId}
              // setPaymentmodal={setPaymentModal}
              paymentDate={paymentDate}
              setPaymentDates={setPaymentDate}
              setDates={setDates}
              setGPersonId={setGPersonId}
              // setrefundCCPaymentPopup={setrefundCCPaymentPopup}
            />
          </div>
        </div>
      </div>
    </>
  );
};
